// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// AdminLTE 3.1.0
@import '~admin-lte/dist/css/adminlte.css';

//FontAwesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

//Datatable
@import '~datatables.net-dt/css/jquery.dataTables.css';
@import '~datatables.net-responsive-dt/css/responsive.dataTables.css';

//Summernote
@import '~summernote/dist/summernote.css';

//Select2
@import '~select2/dist/css/select2.css';

//Select2 Bootstrap
@import '~select2-bootstrap-theme/dist/select2-bootstrap.css';

//SweetAlert 2
@import "~sweetalert2/src/sweetalert2";

//Bootstrap Stepper 
// https://github.com/Johann-S/bs-stepper/tree/v1.7.0#how-to-use-it
@import "~bs-stepper/dist/css/bs-stepper.css";

// Tempus Dominus
@import '~tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4';
